import { useEffect, useState } from 'react';
import { useStore } from '../stores-v2/StoreContext';
import type { DatoContext } from '../stores-v2/types';
import type {
  UserResource,
  SessionResource,
  OrganizationResource,
} from '@clerk/types';

interface InitParams {
  storyId: string | null | undefined;
  env?: string;
  datoCtx?: DatoContext;
  clerkUser?: UserResource | null;
  clerkSession?: SessionResource | null;
  isClerkLoaded?: boolean;
  hasValidAuth: boolean;
}

export function useStoreInitialization({
  storyId,
  env,
  datoCtx,
  clerkUser,
  clerkSession,
  isClerkLoaded,
  hasValidAuth,
}: InitParams) {
  const rootStore = useStore();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!hasValidAuth) return;

    console.debug('Initializing store with:', {
      storyId,
      env,
      datoCtx,
      clerkUser,
      clerkSession,
    });

    async function initializeStore() {
      // Initialize store with basic info
      const organization =
        clerkUser?.organizationMemberships?.[0]?.organization;
      rootStore.initialize({
        storyId,
        env: env || '',
        datoContext: datoCtx,
        organization: organization
          ? ({
              id: organization.id,
              name: organization.name,
              slug: organization.slug,
            } as OrganizationResource)
          : undefined,
      });

      // If we have clerk auth, set up clerk context
      if (clerkSession) {
        try {
          const token = await clerkSession.getToken();
          rootStore.userIdentityStore.setClerkContext(
            //@ts-ignore
            clerkUser,
            clerkSession,
            token || null,
          );
        } catch (error) {
          console.error('Error fetching clerk token:', error);
          return;
        }
      }

      setIsReady(true);
    }

    initializeStore();
  }, [
    storyId,
    env,
    datoCtx,
    clerkUser,
    clerkSession,
    isClerkLoaded,
    hasValidAuth,
  ]);

  return {
    isReady,
    userIdentity: rootStore.userIdentityStore.getUserIdentity(),
  };
}
