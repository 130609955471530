import { TranscriptElement } from '../types.ts/transcript';

import { StoryVideo, Video } from '../types.ts/story';
import { loadAIFlow } from '../aiFlow/AIFlowLoader';
import { AIFlowStep } from '../aiFlow/AIFlowStep';
import { toJS } from 'mobx';
import { equalsIgnoringCase } from '../utility/strings';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import { analytics } from '@src/utility/analytics';

export type ClipFragment = {
  text: string;
  duration: number;
  startTime: number;
  src: string;
  platform: string;
  theme: string;
  transcriptPosition: {
    startIndex: number;
    endIndex: number;
  };
  title: string;
  responseStartIndex: number;
};

// export const defaultSystemMessage =
//   'You get a story as an input and you return multiple short parts of the original story of specified length which sound most better according to a desired theme or mood.';
// export const defaultUserPrompt =
//   'Find the most emotional parts of the following story about love, friendship and relationship. Use exactly the same words from original text. I want the parts to contain approximately {{newVideoWords}} words. Use newlines as a separator between parts. ';

// const defaultModel = 'gpt-3.5-turbo-16k';

// const clipsFunctionCall = [
//   {
//     name: 'return_results_as_array_of_story_parts',
//     description:
//       'Return segments of the original story requested by user as an array, each element of which contains a single part of length and theme specified by user',
//     parameters: {
//       type: 'object',
//       properties: {
//         story_parts: {
//           type: 'array',
//           description:
//             'Array of the most emotional parts of the original story requested by user',
//           items: {
//             type: 'string',
//             description:
//               'The story part which fits user specified length and theme',
//           },
//         },
//       },
//     },
//   },
// ];

const getFlowTitleAndCategoryByTheme = (
  videoCreator: VideoCreatorStore,
  theme: string,
) => {
  const preset = videoCreator.producedContentManager.clipProducerPresets?.find(
    (preset) => preset.title === theme,
  );
  return (
    preset ??
    videoCreator.producedContentManager.clipProducerPresets?.find(
      (preset) => preset.isCustom,
    )!
  );
};

export const getDurationBySocialPlatform = (platform: string) => {
  switch (platform) {
    case 'tiktok':
      return [5, 180];
    case 'instagram':
      return [20, 70];
    case 'youtube':
      return [50, 420];
    case 'youtube-shorts':
      return [10, 60];
    case 'facebook':
      return [20, 70];
    case 'twitter':
      return [10, 120];
    case 'linkedin':
      return [10, 120];
    default:
      return [20, 60];
  }
};

const getRangeForDuration = (duration: number) => {
  return (
    {
      15: [8, 26],
      30: [19, 48],
      60: [39, 80],
      90: [70, 110],
      120: [90, 150],
    }[duration] || [0.7 * duration, 1.3 * duration]
  );
};

export const produceClips = async (params: {
  videoCreator: VideoCreatorStore;
  transcriptionText: string;
  originalVideo: StoryVideo;
  originalDuration: number;
  transcriptionElements: TranscriptElement[];
  duration: number | null;
  theme: string;
  platform: string;
  onClipsProduced?: (clips: ClipFragment[]) => void;
  abortToken: { id: string; aborted: boolean };
}) => {
  const {
    videoCreator,
    transcriptionText,
    transcriptionElements,
    duration,
    originalDuration,
    theme,
    platform,
    originalVideo,
    onClipsProduced,
    abortToken,
  } = params;

  // analytics
  analytics.track('produce_clips', {
    storyId: videoCreator.story?.id,
    storyTitle: videoCreator.story?.title,
    theme,
    platform,
    duration,
  });

  // debugger;
  let [durationMin, durationMax] = getDurationBySocialPlatform(platform);
  if (theme === 'Highlights') {
    [durationMin, durationMax] = [2, 45]; // override for demo
  }
  if (duration) {
    [durationMin, durationMax] = getRangeForDuration(duration);
  }

  const originalVideoWords = transcriptionText.split(' ').length;
  const newVideoWords = Math.round(
    (originalVideoWords * ((durationMin + durationMax) / 2)) / originalDuration,
  );
  const [clipLengthMin, clipLengthMax] = [durationMin, durationMax].map(
    (duration) =>
      Math.round((originalVideoWords * duration) / originalDuration),
  );

  // debugger;
  const clipProducerAiFlow = await loadAIFlow(
    videoCreator,
    getFlowTitleAndCategoryByTheme(videoCreator, theme),
  );
  // debugger;
  if (!clipProducerAiFlow) return [];
  clipProducerAiFlow.context = {
    storyTranscription: transcriptionText,
    storyLength: originalVideoWords,
    storyDuration: originalDuration,
    socialPlatform: platform,
    clipLengthMin,
    clipLengthMax,
    clipLength: newVideoWords,
    clipDurationMin: durationMin,
    clipDurationMax: durationMax,
    clipTheme: theme,
  };

  clipProducerAiFlow.steps[0].mapper = (
    flowContext: any,
    stepContext: AIFlowStep,
  ): (ClipFragment | null)[] => {
    // keep last response index to continue from the last past to reduce computations
    let lastResponseTextIndex = stepContext.context.lastResponseTextIndex || 0;
    let newResultFragments = stepContext.context.previousResultFragments || [];

    const responseSlice =
      stepContext.lastResponse?.slice(lastResponseTextIndex) || '';

    let cumulativeIndex = 0; // Tracks the current position in the responseSlice

    const resultParts = responseSlice
      .split('\n')
      .map((part) => {
        let startIndex = responseSlice.indexOf(part, cumulativeIndex);
        let endIndex = startIndex + part.length;
        cumulativeIndex = endIndex + 1; // Move past this part and the newline character
        startIndex += lastResponseTextIndex;
        endIndex += lastResponseTextIndex;
        return { textAndTitle: part, startIndex, endIndex };
      })
      .filter((q) => q.textAndTitle.trim());

    const NUMBER_OF_WORDS_TO_MATCH = 5;

    if (resultParts.length <= 1) {
      return newResultFragments;
    }

    const resultFragments: (ClipFragment | null)[] = resultParts.map(
      (part, index: number) => {
        const [partText, title] = part.textAndTitle.split(';;;');
        let beginning = stripPunctuationFromEnds(
          partText.split(' ').slice(0, NUMBER_OF_WORDS_TO_MATCH).join(' '),
        );
        let ending = stripPunctuationFromEnds(
          partText.split(' ').slice(-NUMBER_OF_WORDS_TO_MATCH).join(' '),
        );

        if (stepContext.doneStreaming) {
          stepContext.log(
            `Fragment ${index}: \nbeginning: ${beginning}, \nending: ${ending}`,
          );
        }

        const {
          startTime: beginningTime,
          transcriptPosition: beginningTranscriptPosition,
        } = getFragmentPosition(beginning, transcriptionElements) || {};
        if (beginningTime == null || !beginningTranscriptPosition) {
          if (stepContext.doneStreaming) {
            stepContext.log(
              `Fragment ${index} has no beginning position. Cannot find '${beginning}' in the transcription`,
            );
          }
          return null;
        }

        const {
          endTime: endingTime,
          transcriptPosition: endingTranscriptPosition,
        } = getFragmentPosition(ending, transcriptionElements) || {};

        if (endingTime == null || !endingTranscriptPosition) {
          if (stepContext.doneStreaming) {
            stepContext.log(
              `Fragment ${index} has no ending position. Cannot find '${ending}' in the transcription`,
            );
          }
          return null;
        }
        const fragmentDuration = endingTime - beginningTime;

        if (
          fragmentDuration >= durationMin &&
          fragmentDuration <= durationMax
        ) {
          if (stepContext.doneStreaming) {
            stepContext.log(
              `Fragment ${index} has a duration of ${fragmentDuration}s, which is within the allowed range`,
            );
          }
          return {
            text: partText,
            duration: fragmentDuration,
            startTime: beginningTime,
            src: originalVideo?.video.mp4UrlLow || originalVideo.url,
            platform,
            theme,
            transcriptPosition: {
              startIndex: beginningTranscriptPosition.startIndex,
              endIndex: Math.min(
                endingTranscriptPosition.endIndex + 1,
                transcriptionElements.length - 1,
              ), // +1 to compensate for stripPunctuationFromEnds
            },
            title: title || '',
            responseStartIndex: part.startIndex,
          };
        } else if (stepContext.doneStreaming) {
          stepContext.log(
            `Fragment ${index} has a duration of ${fragmentDuration}s, which is NOT within the allowed range: ${durationMin} - ${durationMax}`,
          );
        }
        return null;
      },
    );
    // .filter((part) => !!part)

    const notNullFragments = resultFragments.filter((part) => !!part);
    if (stepContext.doneStreaming) {
      // add last fragment as well
      newResultFragments = newResultFragments.concat(notNullFragments);
      lastResponseTextIndex += responseSlice.length;
    } else if (notNullFragments.length > 1) {
      // don't add the last fragment to the result, as it might be incomplete
      newResultFragments = newResultFragments.concat(
        notNullFragments.slice(0, -1),
      );
      // start from last fragment next time
      lastResponseTextIndex =
        notNullFragments[notNullFragments.length - 1]!.responseStartIndex;
    }

    stepContext.context.lastResponseTextIndex = lastResponseTextIndex;
    stepContext.context.previousResultFragments = newResultFragments;
    return newResultFragments;
  };

  clipProducerAiFlow.steps[0].onPartialResult = (
    result: (ClipFragment | null)[],
  ) => {
    if (abortToken.aborted) {
      throw new Error('Aborted');
    }
    if (result.length > 1) {
      onClipsProduced &&
        onClipsProduced(result.filter((part) => !!part) as ClipFragment[]);
    }
  };
  // debugger;
  await clipProducerAiFlow.run();

  if (abortToken.aborted) {
    throw new Error('Aborted');
  }

  const resultParts = clipProducerAiFlow.result.filter(
    (part: ClipFragment | null) => !!part,
  );

  return resultParts;
};

function getFragmentPosition(
  fragment: string,
  transcriptElements?: TranscriptElement[],
): {
  transcriptPosition: {
    startIndex: number;
    endIndex: number;
  };
  duration: number;
  startTime: number;
  endTime: number;
} | null {
  let targetSentence = fragment;
  // loop through all transcript elements and track the index of the target sentence across multiple elements
  if (!transcriptElements || !targetSentence) return null;

  let targetSentenceCurrIndex = 0;
  let currElementIndex = 0;
  let targetSentenceElementStartIndex = 0;
  let targetSentenceElementEndIndex = 0;
  let found = false;

  while (currElementIndex < (transcriptElements?.length - 1 || 0)) {
    let currElementValue = transcriptElements[currElementIndex].value;

    // if element contains the start of the target sentence save it as the start of the index
    if (
      currElementValue &&
      transcriptElements[currElementIndex]?.state !== 'removed' &&
      transcriptElements[currElementIndex]?.state !== 'cut'
    ) {
      if (
        equalsIgnoringCase(
          targetSentence.substring(
            targetSentenceCurrIndex,
            targetSentenceCurrIndex + currElementValue.length,
          ),
          currElementValue,
        )
      ) {
        if (targetSentenceCurrIndex === 0) {
          targetSentenceElementStartIndex = currElementIndex;
        }
        targetSentenceCurrIndex += currElementValue.length;
      } else {
        targetSentenceCurrIndex = 0;
      }
    }
    if (targetSentenceCurrIndex === targetSentence.length) {
      targetSentenceElementEndIndex = currElementIndex;
      found = true;
      break;
    }
    currElementIndex++;
  }

  // RETURN if not found
  if (!found) return null;

  let ts = null;
  let end_ts = null;

  // loop through all transcript elements and track the index of the target sentence across multiple elements
  currElementIndex = targetSentenceElementStartIndex;
  while (currElementIndex <= targetSentenceElementEndIndex) {
    const currElementFinal = transcriptElements[currElementIndex];

    if (currElementFinal) {
      if (ts === null && currElementFinal.ts != null) {
        ts = currElementFinal.ts - (currElementFinal.buffer_before_ts || 0);
      }
      if (currElementFinal.end_ts) {
        end_ts =
          currElementFinal.end_ts + (currElementFinal.buffer_after_ts || 0);
      }
    }
    currElementIndex++;
  }

  if (ts == null || end_ts == null) return null;

  return {
    transcriptPosition: {
      startIndex: targetSentenceElementStartIndex,
      endIndex: targetSentenceElementEndIndex,
    },
    duration: end_ts - ts,
    startTime: ts,
    endTime: end_ts,
  };
}

function stripPunctuationFromEnds(text: string) {
  return text.replaceAll(
    /^[\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+|[\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/g,
    '',
  );
}

export async function saveProducedClips(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
  sourcePlatform: 'creator-studio' | 'content-studio',
): Promise<void> {
  const originalSource = getOriginalSource(videoCreator);
  const originalVideo = getOriginalVideo(videoCreator);
  const originalDuration = getOriginalDuration(videoCreator);

  if (sourcePlatform === 'creator-studio') {
    // same source should be used for all produced clips
    for (const clip of clips) {
      await saveClipInCreator(
        videoCreator,
        clip,
        originalVideo,
        originalSource,
        originalDuration,
      );
    }
  } else {
    for (const clip of clips) {
      await videoCreator.createNewVideoForContentClip(originalVideo, clip);

      copyClipToCurrentVideo(videoCreator, clip);
      await videoCreator.saveStoryAndVideo(false, false);
    }
  }
}

export async function saveClipFromTranscript(
  videoCreator: VideoCreatorStore,
  clip: any,
) {
  const originalDuration = videoCreator.duration;
  await videoCreator.saveStoryAndVideo();
  const originalVideo = videoCreator.currentVideo;
  const originalSource = videoCreator.renderer?.getSource();
  if (!originalSource || !originalVideo) {
    throw new Error('No video source found to create clip');
  }
  await saveClipInCreator(
    videoCreator,
    clip,
    originalVideo,
    originalSource,
    originalDuration,
  );
}

export async function saveClipInCreator(
  videoCreator: VideoCreatorStore,
  clip: any,
  originalVideo: Video,
  originalSource: any,
  originalDuration: number,
) {
  const newTitle = clip.title || `${originalVideo.title} - ${clip.theme}`;
  await videoCreator.createNewVideoFromSource(
    newTitle,
    originalSource,
    originalVideo.transcriptionChanges,
    originalVideo.transcriptionSnapshot,
    originalVideo.extraElementData,
    'creator-studio',
  );

  await videoCreator.cropTranscriptAndVideoTo(
    clip.transcriptPosition.startIndex,
    clip.transcriptPosition.endIndex,
    originalDuration,
  );

  await videoCreator.setDuration(null);
  copyClipToCurrentVideo(videoCreator, clip);
  await videoCreator.saveStoryAndVideo();
}

function copyClipToCurrentVideo(
  videoCreator: VideoCreatorStore,
  clip: ClipFragment,
) {
  videoCreator.currentVideo!.clipJson = {
    startTime: clip.startTime,
    duration: clip.duration,
  };
}

function getOriginalSource(
  videoCreator: VideoCreatorStore,
): Record<string, any> {
  let originalSource = videoCreator.renderer?.getSource();
  if (!originalSource) {
    originalSource = videoCreator.currentVideo?.videoSource;
  }
  if (!originalSource) {
    throw new Error('No video source found');
  }
  return originalSource;
}

function getOriginalVideo(videoCreator: VideoCreatorStore): Video {
  return toJS(videoCreator.currentVideo!);
}

function getOriginalDuration(videoCreator: VideoCreatorStore): number {
  return videoCreator.duration;
}

export async function saveClipsAsReel(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
  sourcePlatform: 'creator-studio' | 'content-studio',
): Promise<void> {
  if (sourcePlatform === 'creator-studio') {
    await saveClipsAsReelInCreator(videoCreator, clips);
  } else {
    await saveClipsAsReelInContentStudio(videoCreator, clips);
  }
}

async function saveClipsAsReelInCreator(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
) {
  const originalSource = getOriginalSource(videoCreator);
  const originalVideo = getOriginalVideo(videoCreator);
  const originalDuration = getOriginalDuration(videoCreator);
  const newTitle = getReelTitle(videoCreator);

  await videoCreator.createNewVideoFromSource(
    newTitle,
    originalSource,
    originalVideo.transcriptionChanges,
    originalVideo.transcriptionSnapshot,
    originalVideo.extraElementData,
    'creator-studio',
  );

  await videoCreator.cropTranscriptAndVideoInMultiplePlaces(
    clipsToRanges(clips),
    originalDuration,
  );

  await videoCreator.setDuration(null);
  copyClipToCurrentVideo(videoCreator, combineClipsIntoOne(clips, newTitle));
  await videoCreator.saveStoryAndVideo();
}

async function saveClipsAsReelInContentStudio(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
) {
  const originalVideo = getOriginalVideo(videoCreator);
  const newTitle = getReelTitle(videoCreator);
  const combinedClip = combineClipsIntoOne(clips, newTitle);

  await videoCreator.createNewVideoForContentClipsReel(
    originalVideo,
    combinedClip,
    clipsToRanges(clips),
  );

  copyClipToCurrentVideo(videoCreator, combinedClip);
  await videoCreator.saveStoryAndVideo(false, false);
}

function getReelTitle(videoCreator: VideoCreatorStore): string {
  return `${videoCreator.story!.title} - Compilation`;
}

function combineClipsIntoOne(
  clips: ClipFragment[],
  newTitle: string,
): ClipFragment {
  return clips.reduce(
    (acc, clip) => {
      acc.text += clip.text + '\n';
      acc.duration += clip.duration;
      acc.startTime = Math.min(acc.startTime, clip.startTime);
      acc.src = clip.src;
      acc.platform = clip.platform;
      acc.theme = clip.theme;
      acc.transcriptPosition = {
        startIndex: Math.min(
          acc?.transcriptPosition?.startIndex,
          clip.transcriptPosition.startIndex,
        ),
        endIndex: Math.max(
          acc?.transcriptPosition?.endIndex,
          clip.transcriptPosition.endIndex,
        ),
      };
      acc.title = newTitle;
      return acc;
    },
    {
      text: '',
      duration: 0,
      startTime: Infinity,
      transcriptPosition: {
        startIndex: Infinity,
        endIndex: -1,
      },
    } as ClipFragment,
  );
}

function clipsToRanges(
  clips: ClipFragment[],
): { fromElement: number; toElement: number }[] {
  return clips.map((clip) => ({
    fromElement: clip.transcriptPosition.startIndex,
    toElement: clip.transcriptPosition.endIndex,
  }));
}
