import React, { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { marked } from 'marked';
import { observer } from 'mobx-react-lite';
import RotatingSquare from '../../../svgs/RotatingSquare';
import BrandVoiceRevisionModal from '../BrandVoiceRevisionModal';
import ChatGPTService from '../../../services/ChatGPTService';
import SpinningLoading from '../../SpinningLoading';
import { TalkingPointContent } from '../../../types.ts/general';
import { useFlagsCombination } from '../../../utility/useFlagsCombination';
import { capitalize } from '../../../videoTranscriptionProcessor/utils';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

enum title {
  summary = 'Story',
  narrative = 'Narrative',
  impact = 'Impact',
  quote = 'Key Quotes',
  takeaway = 'Takeaways',
  donation = 'Donation',
}

type Revision = (keyof TalkingPointContent & 'all') | null;

type Props = {
  revision: {
    isOpen: boolean;
    value: (keyof TalkingPointContent & 'all') | null;
  };
  toggleRevisionModal: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      value: (keyof TalkingPointContent & 'all') | null;
    }>
  >;
};

const Content = observer(
  forwardRef<HTMLDivElement, Props>((props, ref) => {
    const videoCreator = useVideoCreatorStore();
    const gptService = new ChatGPTService(videoCreator);
    const content = videoCreator.talkingPointContent;
    const story = videoCreator.story;
    const { revision, toggleRevisionModal } = props;
    const { enableTalkingPointsStudioRegeneration } = useFlagsCombination(
      videoCreator.datoContext.currentRole,
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleGenerate = async (brandText: string) => {
      try {
        setIsLoading(true);
        toggleRevisionModal({ ...revision, isOpen: false });
        await gptService.generateTalkingPoint(
          setIsLoading,
          brandText,
          revision.value,
        );
      } catch (error) {}
    };

    const getModalTitle = () => {
      if (!revision.value || revision.value === 'all') return 'Talking Points';

      return `Talking Points: ${title[revision.value]}`;
    };

    if (!content) return null;

    return (
      <Main ref={ref}>
        {content && Boolean(Object.values(content).length) && (
          <>
            <Heading>Talking Points</Heading>
            <StoryInfo className="story-info">
              <h4 className="story-title">{story?.title}</h4>
              <span className="story-teller">{story?.storyTeller?.name}</span>
            </StoryInfo>
            <HorizontalLine />
          </>
        )}

        {Object.entries(content || {}).map(([key, value]) => {
          if (value) {
            return (
              <ContentContainer id={`talkingpoint-${key}`} key={key}>
                <SectionHeader>
                  <ItemHeader className="title">
                    {title[key as keyof typeof title] || capitalize(key)}
                  </ItemHeader>
                  {enableTalkingPointsStudioRegeneration && (
                    <SectionRegenerateButton
                      className="regenerate-action-button"
                      onClick={() => {
                        toggleRevisionModal({
                          isOpen: true,
                          value: key as unknown as Revision,
                        });
                      }}
                    >
                      <RotatingSquare
                        width="19"
                        height="19"
                        strokeColor="#17c964"
                      />
                    </SectionRegenerateButton>
                  )}
                </SectionHeader>
                <ContentData>
                  <ContentBlock
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(value.content),
                    }}
                  />
                </ContentData>

                <HorizontalLine />
              </ContentContainer>
            );
          }
          return null;
        })}

        {isLoading && (
          <SpinningLoading
            customStyle={{
              position: 'fixed',
              top: 0,
              alignItems: 'center',
            }}
            text={`Regenerating ${revision.value ?? ''} content`}
          />
        )}

        {revision.isOpen && (
          <BrandVoiceRevisionModal
            title={getModalTitle()}
            onClose={() => toggleRevisionModal({ isOpen: false, value: null })}
            handleGenerate={handleGenerate}
          />
        )}
      </Main>
    );
  }),
);

export default Content;

const Main = styled.div`
  margin: 0 auto;
  padding: 0 300px;
  max-height: 800px;
  overflow: scroll;
  @media only screen and (max-width: 1100px) {
    padding: 0 200px;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 650px) {
    padding: 0 50px;
  }
`;

const Heading = styled.h2`
  color: #f2d093;
  font-size: 32px;
  font-weight: 700;
`;

const StoryInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 5px;
  .story-title {
    color: #f3e9d7;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  .story-teller {
    color: #a9a9a9;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #484848;
`;

const SectionHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
`;

const ItemHeader = styled.h4`
  color: #f3e9d7;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
`;

const SectionRegenerateButton = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  border: 1;
  display: flex;
  align-items: flex-end;
`;

const ContentData = styled.div`
  p strong {
    font-weight: 800;
  }
  blockquote {
    font-style: italic;
  }
  padding-bottom: 15px;

  ul {
    list-style-type: disc;
  }
  li {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentBlock = styled.div`
  ol {
    list-style-type: none;
  }
  ol > li {
    font-style: italic;
    margin-bottom: 20px;
  }
`;
