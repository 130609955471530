import { FC, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  AlbumQueryResult,
  PhotoArtifactTab,
  Story,
} from '../../types.ts/story';
import useInitializeDatoUser from '../common/useInitializeDatoUser';
import { observer } from 'mobx-react-lite';

import ChatGPTService from '../../services/ChatGPTService';
import SearchInput from '../sidepanel/SearchInput';
import StoryDashboardContent from './StoryDashboardContent';
import ClipDashboardContent from './ClipDashboardContent';
import { checkWordsInString } from '../../utility/general';
import PrimaryActionButton from '../sidepanel/PrimaryActionButton';
import PlayIconRectOutline from '../../svgs/PlayIconRectOutline';
import AddStoriesModal from './user-story/AddStoriesModal';
import NewStoriesQuerySubscriptionComponent, {
  NewStoryUpdate,
} from '../NewStoriesQuerySubscriptionComponent';
import ToastNotification from '../common/ToastNotification';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { v4 as uuid } from 'uuid';
import {
  getAiDescriptionResponse,
  requestMissingFields,
} from '../../utility/story';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { analytics } from '@src/utility/analytics';

type Props = {
  params: {
    [k: string]: string;
  };
};

enum ContentTypes {
  story = 'Stories',
  clip = 'Clips',
}

function buildTargetUrl(
  params: URLSearchParams,
  storyId: string,
  playbackId: string | undefined,
): string {
  return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
    playbackId || ''
  }`;
}

const TEMP_ID_FOR_STORY_BEING_CREATED = 'story-from-modal';

const Dashboard: FC<Props> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  useInitializeDatoUser(videoCreator);
  const album = videoCreator.organization;
  const [allStories, setAllStories] = useState<AlbumQueryResult['stories']>([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [selectedTab, setSelectedTab] = useState<ContentTypes>(
    ContentTypes.story,
  );
  const [inProgressModalIds, setInProgressModalIds] = useState<string[]>([]);
  const [bannerHeight, setBannerHeight] = useState<number | undefined>();
  const [newStoryCompletionPercentRecord, setNewStoryCompletionPercentRecord] =
    useState<Record<string, number>>({});
  const { storyManagerEnableAddStoriesButton } = useFlagsCombination(
    videoCreator.datoContext.currentRole,
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const gptService = new ChatGPTService(videoCreator);

  useEffect(() => {
    if (!album?.id) return;
    (async () => {
      const stories = (await videoCreator.findManyStories(
        album.id,
      )) as AlbumQueryResult['stories'];
      setAllStories(stories);
      initNewStoryCompletionPercentRecord(stories);
    })();
  }, [album]);

  function resetStoryStates(storyId: string) {
    const prevStoryId = urlSearchParams.get('storyId');
    if (prevStoryId !== storyId) {
      videoCreator.selectedPhotoAssets = {
        tab: PhotoArtifactTab.story,
        resource: undefined,
      };
      videoCreator.talkingPointContent = null;
      if (gptService.talkingPointController) {
        gptService.talkingPointController.abort();
      }
    }
  }

  const initNewStoryCompletionPercentRecord = (
    stories: AlbumQueryResult['stories'],
  ) => {
    const record: Record<string, number> = {};
    stories.forEach((s) => {
      if (isStoryLoading(s)) {
        record[s.id] = calculateCompletionPercent(s);
      }
    });
    setNewStoryCompletionPercentRecord(record);
  };

  const hasUploadFailed = (s: NewStoryUpdate): boolean => {
    return s.useAws
      ? !!s.uploadLog?.handleFailure
      : !!s.uploadLog?.handleFailure &&
          s.uploadLog?.initialUpload?.status !== 'success';
  };

  const isVideoUploadStuck = (s: NewStoryUpdate): boolean => {
    return (
      s.uploadLog?.initialUpload?.status !== 'success' &&
      Date.now() - new Date(s.uploadLog?.initialUpload?.updatedAt).getTime() >
        60000
    );
  };

  const isStoryOriginalVideoReady = (s: NewStoryUpdate): boolean => {
    return !s.useAws
      ? !!s.originalVideo?.video?.thumbnailUrl ||
          !!s.datoHostedVideo?.video?.thumbnailUrl
      : s.uploadLog?.ffmpegProcessing?.status === 'success';
  };

  const isStoryTranscriptionReady = (s: NewStoryUpdate): boolean => {
    return s.useAws
      ? s.uploadLog?.combineResults?.status === 'success'
      : ['FAILED', 'COMPLETED'].includes(s.transcription?.jobStatus);
  };

  const isStoryProcessingStarted = (s: NewStoryUpdate): boolean => {
    return s.uploadLog?.initialUpload?.status === 'success';
  };

  const calculateCompletionPercent = (
    story: AlbumQueryResult['stories'][0],
  ): number => {
    if (isStoryOriginalVideoReady(story) && isStoryTranscriptionReady(story)) {
      return 100;
    } else if (
      isStoryOriginalVideoReady(story) ||
      isStoryTranscriptionReady(story)
    ) {
      return 75;
    } else if (isStoryProcessingStarted(story)) {
      return 50;
    }
    return Math.round((story.uploadLog?.initialUpload?.progress || 0) / 2);
  };

  const getDescription = (story: AlbumQueryResult['stories'][0]) => {
    return getAiDescriptionResponse(story)?.response?.toString() || '';
  };

  const handleSearchQueryChange = (text: string) => {
    setSearchQuery(text);
  };

  const filterStoriesBySearchQuery = (text: string) => {
    if (!text.length) {
      return allStories;
    }
    const value = text.toLowerCase();
    const stories = allStories.filter((s) => {
      const summary = getDescription(s);
      return (
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value) ||
        checkWordsInString(summary, value)
      );
    });
    return stories;
  };

  const filterClipsBySearchQuery = (text: string) => {
    if (!text.length) {
      return allStories;
    }
    const value = text.toLowerCase();

    const newStories = [];
    for (let s of allStories) {
      const isInStory =
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value);
      if (isInStory) {
        newStories.push(s);
        continue;
      }
      const clips = s.otherVideos.filter((v) => {
        return checkWordsInString(v.title, value);
      });
      newStories.push({ ...s, otherVideos: clips });
    }
    return newStories;
  };

  const handleNewStoryUpdateReceived = async (
    storyUpdates: NewStoryUpdate[],
  ) => {
    const newAllStories: AlbumQueryResult['stories'] = [];
    for (let story of allStories) {
      const storyUpdate = storyUpdates.find((u) => u.id === story.id);
      if (storyUpdate) {
        if (!story.originalVideo && isStoryOriginalVideoReady(storyUpdate)) {
          const selfHostedVideo = (
            await requestMissingFields(story.id, ['originalVideo'])
          ).originalVideo;
          story.originalVideo = story.useAws
            ? selfHostedVideo!
            : story.datoHostedVideo!;
        }
        notifyAboutNewStoryUpdate(storyUpdate, story);
        newAllStories.push({ ...story, ...storyUpdate });
      } else {
        newAllStories.push(story);
      }
    }
    setAllStories(newAllStories);
  };

  const notifyAboutNewStoryUpdate = (
    story: NewStoryUpdate,
    item: AlbumQueryResult['stories'][0],
  ) => {
    if (hasUploadFailed(story)) {
      handleNewStoryProgress(100, story.id);
      // videoCreator.toastState = {
      //   state: 'error',
      //   message: 'Failed to upload new story',
      // };
      return;
    }

    if (
      isStoryOriginalVideoReady(story) &&
      isStoryTranscriptionReady(story) &&
      // prevent if update is not new
      !(isStoryOriginalVideoReady(item) && isStoryTranscriptionReady(item))
    ) {
      handleNewStoryProgress(100, story.id);
      // videoCreator.toastState = {
      //   state: 'success',
      //   message: 'Story successfully uploaded',
      // };
      return;
    }

    if (
      (isStoryOriginalVideoReady(story) || isStoryTranscriptionReady(story)) &&
      // prevent if update is not new
      !(isStoryOriginalVideoReady(item) || isStoryTranscriptionReady(item))
    ) {
      handleNewStoryProgress(75, story.id);
      return;
    }

    // if last update is over one minute ago, consider it failed
    if (!hasUploadFailed(story) && isVideoUploadStuck(story)) {
      handleNewStoryProgress(100, story.id);
      // save upload failed state
      try {
        const timestamp = new Date();
        videoCreator.storyRepository!.updateStoryStepsLog({
          id: story.uploadLog.id,
          initial_upload: JSON.stringify({
            status: 'fail',
            updatedAt: timestamp,
          }),
          handle_failure: JSON.stringify({
            status: 'fail',
            error: 'Video upload stuck',
            updatedAt: timestamp,
          }),
        });
        console.log('Video upload stuck, marked as failed', story.id);
      } catch (err) {
        console.error(
          'Error updating story upload log with video upload progress',
          err,
        );
      }
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to upload new story',
      };
      return;
    }

    if (
      story.useAws &&
      story.uploadLog?.combineResults?.status === 'fail' &&
      // prevent if update is not new
      !(item.uploadLog?.combineResults?.status === 'fail')
    ) {
      handleNewStoryProgress(100, story.id);
      videoCreator.toastState = {
        state: 'error',
        message: 'Transcription generation failed for new story',
      };
      return;
    }

    if (
      !story.useAws &&
      story.externalUploadStatus === 'FAILED' &&
      // prevent if update is not new
      !(item.externalUploadStatus === 'FAILED')
    ) {
      handleNewStoryProgress(100, story.id);
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to upload new story',
      };
      return;
    }

    if (story.useAws && story.uploadLog?.initialUpload?.progress) {
      handleNewStoryProgress(
        Math.round(story.uploadLog?.initialUpload?.progress / 2),
        story.id,
      );
    }
  };

  const handleAddStoriesButtonClick = () => {
    analytics.track('add_stories_button_clicked');

    const modalId = uuid();
    setInProgressModalIds([...inProgressModalIds, modalId]);
  };

  const closeModal = (modalId: string) => {
    setInProgressModalIds(inProgressModalIds.filter((id) => id !== modalId));
  };

  const handleAddStoriesModalClose = (modalId: string) => {
    // premature closing should hide banner
    handleNewStoryProgress(0, TEMP_ID_FOR_STORY_BEING_CREATED, true);
    closeModal(modalId);
  };

  const getNewStoryCompletionPercent = (
    storyId?: string,
    currentCompletionRecord?: any,
  ): number => {
    const completionRecord =
      currentCompletionRecord || newStoryCompletionPercentRecord;
    const key = storyId || TEMP_ID_FOR_STORY_BEING_CREATED;
    if (key in completionRecord) {
      return completionRecord[key];
    } else {
      return 0;
    }
  };

  const handleNewStoryProgress = (
    percent: number,
    storyId?: string,
    reset?: boolean,
  ) => {
    setNewStoryCompletionPercentRecord((currentCompletionRecord) => {
      const updatedRecord = {
        ...currentCompletionRecord,
      };
      if (
        storyId &&
        percent > getNewStoryCompletionPercent(storyId, currentCompletionRecord)
      ) {
        updatedRecord[storyId] = percent;
      }
      if (
        !storyId &&
        percent > getNewStoryCompletionPercent(storyId, currentCompletionRecord)
      ) {
        updatedRecord[TEMP_ID_FOR_STORY_BEING_CREATED] = percent;
      }
      if (reset) {
        updatedRecord[TEMP_ID_FOR_STORY_BEING_CREATED] = 0;
      }
      return updatedRecord;
    });
  };

  const handleNewStoryInitialized = (story: Story) => {
    const newStory = story as unknown as AlbumQueryResult['stories'][0];
    // transfer progress from temp id to created story id, will hide banner
    handleNewStoryProgress(getNewStoryCompletionPercent(), newStory.id, true);
    setAllStories([newStory, ...allStories]);
  };

  const handleNewStoryCreated = (story: Story, modalId: string) => {
    const newStory = story as unknown as AlbumQueryResult['stories'][0];
    handleNewStoryProgress(
      Math.max(50, getNewStoryCompletionPercent(newStory.id)),
      newStory.id,
      true,
    );
    setAllStories(
      allStories.map((item) => (item.id === newStory.id ? newStory : item)),
    );
    closeModal(modalId);
  };

  const isStoryLoading = (s: AlbumQueryResult['stories'][0]): boolean => {
    return (
      s.externalUploadStatus !== 'FAILED' &&
      !hasUploadFailed(s) &&
      (!isStoryOriginalVideoReady(s) || !isStoryTranscriptionReady(s))
    );
  };

  const newStoryIds = allStories
    .filter((s) => isStoryLoading(s) || !getAiDescriptionResponse(s))
    .map((s) => s.id);

  const getStoryCompletionPercent = (
    s: AlbumQueryResult['stories'][0],
  ): number => {
    return getNewStoryCompletionPercent(s.id);
  };

  const getStoriesWithCompletionPercent = (
    stories: AlbumQueryResult['stories'],
  ) => {
    return stories.map((s) => {
      return { ...s, completionPercent: getStoryCompletionPercent(s) };
    });
  };

  const handleStoryDelete = async (story: AlbumQueryResult['stories'][0]) => {
    setAllStories(allStories.filter((s) => s.id !== story.id));

    try {
      await videoCreator.softDeleteStory(story.id);
    } catch (e) {
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to delete story',
      };
      setAllStories(allStories);
    }
  };

  const stories = useMemo(() => {
    if (selectedTab === ContentTypes.story) {
      return filterStoriesBySearchQuery(searchQuery);
    } else if (selectedTab === ContentTypes.clip) {
      return filterClipsBySearchQuery(searchQuery);
    }
    return allStories;
  }, [allStories, searchQuery, selectedTab]);

  return (
    <Main bannerHeight={bannerHeight}>
      <TopContent>
        <Title>Story Manager</Title>
        <Tabs>
          {Object.entries(ContentTypes).map(([k, v]) => (
            <Tab
              onClick={() => setSelectedTab(v)}
              isSelected={selectedTab === v}
            >
              {v}
            </Tab>
          ))}
        </Tabs>
        <SearchInput
          iconRight={true}
          placeholder={
            selectedTab === ContentTypes.story
              ? 'Search your stories'
              : 'Search your clips'
          }
          radius="10px"
          width="300px"
          handleAction={(text) => handleSearchQueryChange(text)}
          autoSubmitDelay={500}
        />
        {selectedTab === ContentTypes.story &&
          storyManagerEnableAddStoriesButton && (
            <>
              <AddStoriesButton
                onClick={handleAddStoriesButtonClick}
                isActivated
              >
                <PlayIconRectOutline />
                Add Stories
              </AddStoriesButton>
            </>
          )}
      </TopContent>

      <Table>
        {(() => {
          switch (selectedTab) {
            case ContentTypes.story:
              return (
                <StoryDashboardContent
                  stories={getStoriesWithCompletionPercent(stories)}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                  isStoryLoading={isStoryLoading}
                  isStoryFailed={hasUploadFailed}
                  getStoryCompletionPercent={getStoryCompletionPercent}
                  onStoryDelete={handleStoryDelete}
                />
              );

            case ContentTypes.clip:
              return (
                <ClipDashboardContent
                  stories={stories}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                />
              );
          }
        })()}
      </Table>
      {inProgressModalIds.map((modalId) => (
        <AddStoriesModal
          key={modalId}
          closeModal={handleAddStoriesModalClose}
          onStoryInitialized={handleNewStoryInitialized}
          onStoryCreated={handleNewStoryCreated}
          onStoryProgress={handleNewStoryProgress}
          modalId={modalId}
          setBannerHeight={setBannerHeight}
        />
      ))}
      {!!newStoryIds.length && (
        <NewStoriesQuerySubscriptionComponent
          key={newStoryIds.join('_')}
          storyIds={newStoryIds}
          onDataReceived={handleNewStoryUpdateReceived}
          onError={() => {
            videoCreator.toastState = {
              state: 'error',
              message: 'Failed to update new story',
            };
          }}
        />
      )}
      {videoCreator.toastState && <ToastNotification />}
    </Main>
  );
});

export default Dashboard;

const Main = styled.div<{ bannerHeight?: number }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  padding-bottom: ${(props) => `${(props.bannerHeight || 0) + 20}`}px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  min-width: 440px;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  color: #f3e9d7;
  font-weight: 700;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Tabs = styled.div`
  display: flex;
  gap: 16px;
  margin-right: auto;
  margin-left: 50px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3e9d7;
  cursor: pointer;
  &:hover {
    color: #f2d093;
    text-decoration: underline;
  }
  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
      text-decoration: underline;
    `}
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: 'Inter', sans-serif;
`;

const AddStoriesButton = styled(PrimaryActionButton)`
  width: 240px;
  max-width: 240px;
  margin-left: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;
